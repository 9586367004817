<template>
  <div class="flex-auto h-1 min-h-0 overflow-y-auto" ref="listContainer">
    <ol class="" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
      <li
        v-for="item in dataRef?._items"
        class="border-l-2 border-b border-b-neutral-200"
        :class="[
          viewerStore.currentLocationId === item.id
            ? `border-l-sky-500 bg-sky-100`
            : `border-l-transparent hover:bg-neutral-50 hover:border-l-neutral-500`,
        ]"
      >
        <button
          type="button"
          class="block w-full px-2.5 py-3 cursor-pointer border-l-2 border-l-transparent aria-pressed:border-l-sky-500 group"
          :aria-pressed="viewerStore.currentLocationId === item.id"
          @click="viewerStore.setCurrentLocationUsingILocation(item)"
        >
          <div class="mb-2">
            <div class="flex">
              <div class="flex-1 text-left">
                <div
                  class="font-medium text-neutral-800 group-aria-pressed:text-neutral-900 text-sm break-words line-clamp-2"
                >
                  {{ item.title }}
                </div>
                <div class="text-sm text-neutral-600 line-clamp-1 group-aria-pressed:text-neutral-800">
                  {{ viewerStore.getDataset(item.datasetIdentifier).label }}
                </div>
              </div>
              <PinIcon
                class="rounded-full bg-neutral-300 ml-1.5 flex-none self-start inline-flex items-center justify-center h-9 w-9"
                :viewer-color="viewerStore.getDataset(item.datasetIdentifier).datasetGroup.colors"
              />
            </div>
          </div>
          <div class="text-sm text-neutral-600 text-left break-words line-clamp-3 group-aria-pressed:text-neutral-800">
            {{ item.subTitle }}{{ item.summary?.length > 0 ? " - " : " " }}{{ item.summary }}
          </div>
          <!-- <div>{{ item.recordDate }}</div> -->
        </button>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { useCssBreakpointStore } from "@/components/common/cssBreakpointStore";
import { useAppSettingsStore } from "@/components/common";
import { useInfiniteScroll } from "@vueuse/core";
import { defineComponent, inject, ref, watch } from "vue";
import PinIcon from "@/components/viewer/PinIcon.vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { DATA_STATUS, ViewerListProps } from "@/components/viewer";
import { useViewerStore } from "@/components/viewer/viewerStore";

/**
 * Viewer Panel to display the currently mapped items in a list.
 * It uses the VueUse `useInfiniteScroll` helper to prevent us from rendering everything.
 * As the user scrolls, more records are fetched based on the `defaultTake` value in Viewer.vue
 */
export default defineComponent({
  name: "ViewerPanelList",
  components: {
    PinIcon,
  },
  setup(props, context) {
    const viewerStore = useViewerStore();
    const appSettingsStore = useAppSettingsStore();
    const cssBreakpointStore = useCssBreakpointStore();

    const { debouncedSearch, dataRef, isBouncingRef, isStatusPending, filterChangeHandler } = inject(
      injectionSymbols.ViewerListPropsKey,
    ) as ViewerListProps;

    const listContainer = ref<HTMLElement | null>(null);

    function initiateScroll() {
      filterChangeHandler({
        field: "datasetRequest",
        filterValue: viewerStore.datasetPayloadRef,
        isDefault: false,
        isIgnored: false,
      });

      const { reset } = useInfiniteScroll(
        listContainer,
        () => {
          if (dataRef.value && dataRef.value._items.length === dataRef.value.totalResultsCount) {
            // $state.loaded();
            return;
          }
          if (isBouncingRef.value || isStatusPending.value) {
            return;
          }
          if (dataRef.value === undefined) {
            return;
          }
          let isPaged = dataRef.value && dataRef.value._items.length > 0;
          debouncedSearch(isPaged);
        },
        { distance: 10 },
      );

      watch(
        () => dataRef.value,
        (newVal) => {
          if (!newVal || !newVal._items || !newVal._items.length) {
            reset();
          }
        },
      );
    }

    // waiting to start the infinite scroll until we have the dataset metadata needed to run the api call
    const initialSearchWatch = watch(
      () => viewerStore.dataStatus,
      (newVal) => {
        if (newVal === DATA_STATUS.hasDatasets) {
          initiateScroll();
          initialSearchWatch();
        } else {
          return;
        }
      },
      { immediate: true },
    );

    return {
      listContainer,
      appSettingsStore,
      viewerStore,
      dataRef,
    };
  },
});
</script>

<style module></style>
