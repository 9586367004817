<template>
  <FormKit
    type="autocomplete"
    :label="label"
    :name="field"
    :value="selectedOptions"
    :placeholder="placeholder"
    :options="allItemsRef"
    :option-loader="optionLoader"
    @node="setNode"
    empty-message="None Found"
    multiple
    selection-appearance="option"
    validation="has_no_validation"
    :close-on-select="false"
    selections-class="flex flex-wrap gap-x-2 mt-2"
    inner-class="bg-white"
    label-class="text-neutral-800"
    selectionWrapper-class="shadow-sm"
  >
    <template #selection="{ option, classes }">
      <div :class="classes.selection">
        <div :class="`${classes.option} `">
          {{ option.label }}
        </div>
      </div>
    </template>
  </FormKit>
</template>

<script lang="ts">
import type { ListFilterLabels, ListFiltersHelpers, ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { capitalizeString } from "@/utils/filters";
import { FormKitNode } from "@formkit/core";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/24/solid";
import { useSessionStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { PropType, Ref, computed, defineComponent, inject, ref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { LIST_DROPDOWN_FILTER_MULTIPLE } from "@/components/list/genericList/componentNames";

/** Provides a multi-select FormKit autocomplete component that will filter the entity's list results. */
export default defineComponent({
  name: LIST_DROPDOWN_FILTER_MULTIPLE,
  components: {
    TransitionChild,
    TransitionRoot,
    ChevronDownIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ListboxLabel,
    CheckIcon,
  },
  props: {
    /** Label for the dropdown */
    label: {
      type: String,
      default: (props: any) => capitalizeString(props.field),
    },
    /** Optional specification for the plural label - will default to just adding `s` to the end of the `label`*/
    labelPlural: {
      type: String,
      default: (props: any) => props.label + "s",
    },
    /** name of the field to be used to filter the results by */
    field: {
      type: String,
      required: true,
    },
    /** Formkit compatabile array for options for the dropdown */
    items: {
      type: Object as PropType<{ label: string; value: any }[]>,
      // required: true,
      default: (props: any) => {
        return props.itemsPromise ? undefined : ["invalid"];
      },
      validator: (value: string[]) => {
        let isValid = value.length === 0 || (value.length > 0 && value[0] != "invalid");
        if (!isValid) {
          //throwing error to prevent error from looping
          throw new Error("Either items or itemsPromise must be present");
        }
        return isValid;
      },
    },
    /** Promise that return a formkit compatabile array for options for the dropdown. Used for when items need to be fetched from the BE instead of something like enums. */
    itemsPromise: {
      type: Promise<any>,
      //required: true,
    },
    /** @experimental - this prop is not currently in use, but may be in future releases */
    addDefault: {
      type: Boolean,
      default: true,
    },
    /** @experimental - this prop is not currently in use, but may be in future releases */
    idField: {
      type: String,
      default: () => "id",
    },
    /** @experimental - this prop is not currently in use, but may be in future releases */
    descriptionField: {
      type: String,
      default: () => "All",
    },
  },
  setup(props, context) {
    const route = useRoute();
    const storeName = route.name?.toString() + "-Filter" + props.field;

    const allItems: any = ref();
    const allItemsRef = computed(() =>
      allItems.value ? allItems.value.map((x: any) => ({ ...x, value: x.value.toString() })) : [],
    );

    const itemsMap = new Map<any, string>([]);

    const { filterChangeHandler } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;
    const { registerAndUpdateFilters } = inject(injectionSymbols.ListFilterLabelsKey) as ListFilterLabels;
    const { getIndexOfFilterComponent } = inject(injectionSymbols.ListFiltersHelpersKey) as ListFiltersHelpers;

    const isQueryBlank = Object.entries(route.query).length === 0;
    const selectedQueryRef = useRouteQuery(props.field);

    let getFilterDescriptions = (val: string[]) => (val ? val.map((item) => itemsMap.get(item) ?? "") : []);

    const selectedSessionRef: Ref<string[]> = useSessionStorage<any[]>(storeName, []) as Ref<string[]>;
    if (isQueryBlank && selectedSessionRef.value) {
      selectedQueryRef.value = selectedSessionRef.value;
    }
    let selectedOptions = ref();
    selectedOptions.value = Array.isArray(selectedQueryRef.value)
      ? selectedQueryRef.value
      : !selectedQueryRef.value
      ? []
      : [selectedQueryRef.value];
    let isValueUpdatedByQuery = false;

    if (props.itemsPromise) {
      if (selectedOptions.value && selectedOptions?.value.length > 0) {
        for (let i = 0; i < selectedOptions.value.length; i++) {
          itemsMap.set(selectedOptions.value[i], "Loading...");
        }
      }
    }

    registerAndUpdateFilters({
      label: props.label,
      value: getFilterDescriptions(selectedOptions.value),
      field: props.field,
      sortOrder: getIndexOfFilterComponent(props.field),
    });

    const isDefault = () =>
      (selectedOptions.value?.length === 1 && selectedOptions.value.includes("")) ||
      selectedOptions.value?.length === 0;
    const isIgnored = () =>
      (selectedOptions.value?.length === 1 && selectedOptions.value.includes("")) ||
      selectedOptions.value?.length === 0;

    let dropdown: FormKitNode<unknown> | undefined;

    const placeholder = ref("All " + props.labelPlural);
    const setPlaceholder = (val: string[]) => {
      placeholder.value = selectedOptions.value?.length ?? 0 > 0 ? "" : "All " + props.labelPlural;
    };
    setPlaceholder(selectedOptions.value);

    const setNode = (dropdownInput: FormKitNode<unknown>) => {
      dropdown = dropdownInput;
      dropdown?.on("input", async ({ payload }) => {
        if (isValueUpdatedByQuery) {
          isValueUpdatedByQuery = false;
          return;
        }
        selectedOptions.value = payload;
        setPlaceholder(selectedOptions.value);
        const queryValue = isIgnored() ? undefined : payload;
        selectedQueryRef.value = queryValue;
      });
    };

    onBeforeRouteUpdate((to, from) => {
      const newValue = to.query[props.field];
      const oldValue = from.query[props.field];
      if (newValue === oldValue) {
        return;
      } else {
        const val: any = Array.isArray(newValue) ? (newValue.filter((x) => x !== null) as string[]) : [];
        isValueUpdatedByQuery = selectedOptions.value !== val;
        selectedOptions.value = val;
        setPlaceholder(selectedOptions.value);
        dropdown?.input(val);
      }

      selectedSessionRef.value = selectedOptions.value;

      registerAndUpdateFilters({
        label: props.label,
        value: getFilterDescriptions(selectedOptions.value),
        field: props.field,
        sortOrder: getIndexOfFilterComponent(props.field),
      });

      filterChangeHandler({
        field: props.field,
        filterValue: selectedOptions.value ?? [],
        isDefault: isDefault(),
        isIgnored: isIgnored(),
      });
    });

    if (!isIgnored()) {
      filterChangeHandler({
        field: props.field,
        filterValue: selectedOptions.value?.length ? selectedOptions.value : [],
        isDefault: isDefault(),
        isIgnored: isIgnored(),
      });
    }

    function setItemsMap() {
      allItemsRef.value.forEach((item: { label: string; value: any; __original?: any }) => {
        if (item.__original) {
          itemsMap.set(item.__original, item.label);
        } else {
          itemsMap.set(item.value, item.label);
        }
      });
    }

    if (props.itemsPromise) {
      props.itemsPromise.then((result) => {
        allItems.value = result;
        setItemsMap();
        registerAndUpdateFilters({
          label: props.label,
          value: getFilterDescriptions(selectedOptions.value),
          field: props.field,
          sortOrder: getIndexOfFilterComponent(props.field),
        });
      });
    } else {
      allItems.value = props.items;
      setItemsMap();
      registerAndUpdateFilters({
        label: props.label,
        value: getFilterDescriptions(selectedOptions.value),
        field: props.field,
        sortOrder: getIndexOfFilterComponent(props.field),
      });
    }

    const resultToOption = (id: number) => {
      const result = {
        label: itemsMap.get(id),
        value: id.toString(),
      };

      return result;
    };

    const optionLoader = async (id: any, cachedOption: any) => {
      if (props.itemsPromise) {
        return resultToOption(id);
      } else {
        return resultToOption(id);
      }
    };

    return {
      placeholder,
      allItemsRef,
      selectedOptions,
      filterChangeHandler,
      setNode,
      optionLoader,
    };
  },
});
</script>
