// import { Viewer } from "@mui/components/viewer";
import { RouterView } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  documentation: "Documentation",
};

export { names as documentationRouteNames };

const documentationRoutes = [
  {
    path: "/documentation",
    name: names.documentation,
    meta: { title: "Documentation" },
    component: () => import("@casa/views/documentation/Documentation.vue"),
  },
];
export default documentationRoutes;
