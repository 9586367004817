import { ref, readonly } from "vue";

const userRef = ref<{ [key: string | number | symbol]: any }>({
  isAuthenticated: false,
});

/**
 * Return a readonly user state to prevent direct mutation
 */
export const getUser = () => readonly(userRef);

/**
 * Set new user state
 */
export const setUser = (data: any) => (userRef.value = data);

/**
 * Update user state value by the key passed
 */
export const updateUser = (key: string | number | symbol, value: any) => (userRef.value[key] = value);

const readonlyUser = getUser();

export const useUser = () => {
  return {
    userRef: readonlyUser,
    setUser,
    updateUser,
  };
};
