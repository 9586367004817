<template>
  <LayoutAppContent>
    <div class="h-full overflow-auto" :class="{ 'bg-neutral-100': isAuthenticated }">
      <div class="">
        <!-- content -->
        <div class="mx-auto mb-5 mt-5 max-w-5xl px-2" v-if="isAuthenticated">
          <div class="mx-3 sm:mx-5 md:mx-8 xl:mx-0">
            Welcome authenticated user
            <!-- <h2 class="text-sm font-medium text-neutral-500">Features</h2>
            <ul role="list" class="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-6">
              <template v-for="item in navItems" :key="item.name">
                <SkeletonButton v-if="item.isAuthPending" class="col-span-1" />
                <li v-if="item.isAuthorized" class="col-span-1 flex rounded-md shadow-sm">
                  <router-link
                    :to="{ name: item.name }"
                    class="flex flex-1 items-start justify-between rounded-md border border-neutral-200 bg-white text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 hover:text-sky-900 focus:border-sky-200 focus:bg-sky-100 focus:text-sky-900"
                  >
                    <div class="flex-1 px-4 py-2 text-sm">
                      <div class="font-medium">{{ item.label }}</div>
                    </div>
                  </router-link>
                </li>
              </template>
            </ul> -->
          </div>
        </div>
        <div class="mx-auto mb-5 max-w-5xl px-2" v-if="isInternal">
          Welcome internal user
          <!-- <div class="mx-3 sm:mx-5 md:mx-8 xl:mx-0">
            <h2 class="text-sm font-medium text-neutral-500">Analytics</h2>
            <ul role="list" class="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-6">
              <template v-for="item in analyticsNavItem.items" :key="item.name">
                <SkeletonButton v-if="analyticsNavItem.isAuthPending" class="col-span-1" />
                <li class="col-span-1 flex rounded-md shadow-sm" v-if="analyticsNavItem.isAuthorized">
                  <router-link
                    :to="{ name: item.name }"
                    class="flex flex-1 items-start justify-between rounded-md border border-neutral-200 bg-white text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 hover:text-sky-900 focus:border-sky-200 focus:bg-sky-100 focus:text-sky-900"
                  >
                    <div class="flex-1 px-4 py-2 text-sm">
                      <div class="font-medium">{{ item.label }}</div>
                      <p class="text-neutral-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>
          </div> -->
        </div>
        <SimpleMap class="h-96 w-full bg-neutral-800" :topo-json="hiTopoJson" />

        <!-- <div class="p-5 text-center sm:pt-10" v-if="isBureauOrHd || isInternal">
          <h1
            class="relative inline-block w-full rounded-lg border-4 border-neutral-300 bg-white p-12 text-center sm:w-1/2"
          >
            <FaceSmileIcon class="mx-auto h-12 w-12 text-neutral-400"></FaceSmileIcon>
            <span class="mt-4 block text-xl font-medium text-neutral-900"
              >Welcome {{ userRef.firstName }}!</span
            >
          </h1>
        </div> -->

        <!-- Home features content for auth'd and unauth'd users alike. We toggle widgets based on auth status. (and eventually, user profile config) -->
        <div
          v-if="!isAuthenticated && !isInternal"
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">Casa</h1>
              <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
                <span class="font-semibold"><LoginButton class="hover:underline" /></span>
                or
                <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton>
                to get started
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>Casa is a system used to develop, test, and demonstrate MUI components.</p>
              </div>
              <!-- put the stuff we demonstrate here?  -->

              <!-- <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Recycling</h3>
                <ul>
                  <li>Submit annual recycling reports</li>
                  <li>View historical recycled and diverted materials</li>
                  <li>Secure access to your company or agency data</li>
                </ul>
              </div>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Solid Waste Disposal</h3>
                <ul>
                  <li>Submit quarterly, semi-annual, and annual disposal reports</li>
                  <li>View historical disposal data</li>
                  <li>Secure access to your facility data</li>
                </ul>
              </div>-->
            </div>
            <div><router-link to="viewerTest">link plain</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 1 } }">link 1</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 2 } }">link 2</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 3 } }">link 3</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 4 } }">link 4</router-link></div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
              <BaseButton
                variant="priority"
                class="w-full sm:w-auto"
                label="Register"
                @click.prevent="() => $authn.redirectToRegister()"
              ></BaseButton>
            </div>
          </div>
        </div>
      </div>
      <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
        <MdrnAttribution />
      </footer>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import { useAuthorization } from "@/utils/auth/authorization";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { InitialBadge } from "@mui/components/badge";
import { BaseButton } from "@mui/components/button";
import {
  AppNavItemGroup,
  MdrnAttribution,
  // LayoutAppContent,
  NoneFoundFlag,
  SkeletonButton,
  useAppNav,
} from "@mui/components/common";
import { SimpleMap } from "@mui/components/simpleMap";
import { useViewerStore } from "@mui/components/viewer";
import { LoginButton, RegisterButton, useUser } from "@mui/utils/auth/authentication";
import {
  // SimpleMap,
  // SkeletonButton,
  noLabelSchema,
  sortByAlphabeticalByField,
} from "@mui/utils/helpers";
import { computed, defineComponent, ref, watch } from "vue";
import { additionalMetadata, metadataContext } from "./viewer/viewerSettings";
// import { getFacilitySites } from "@casa/views/facilitySite/facilitySiteApi";
import hiTopoJson from "@casa/constants/hawaii-counties-topo.json";

export default defineComponent({
  setup() {
    const viewerStore = useViewerStore();
    viewerStore.metadataContext = metadataContext;
    viewerStore.additionalMetadata = additionalMetadata;
    viewerStore.hydrateDatasetMetadata();
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();
    const { navigationItems, userNavigationItems } = useAppNav();

    const isBureauAR = new AuthRequest();
    isBureauAR.roleName = "Bureau";

    const isHealthDistrictAR = new AuthRequest();
    isHealthDistrictAR.roleName = "HealthDistrict";

    const isBureau = ref(false);
    const isHealthDistrict = ref(false);
    authorizationClient
      .isAuthorizedArray([isBureauAR, isHealthDistrictAR])
      .then(([isBureauResult, isHealthDistrictResult]) => {
        isBureau.value = isBureauResult;
        isHealthDistrict.value = isHealthDistrictResult;
      }, handlePreempts);

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    type userClaims = {
      type: string;
      value: string;
    };

    const isBureauOrHd = computed(() => isBureau.value || isHealthDistrict.value);
    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: userClaims) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          // execGetOrganizations({ expand: ["RecyclingReports"] });
          // execGetFacilitySites();
        }
      },
      { immediate: true },
    );

    const rrAccessRequest = ref({ entityName: "", requestText: "" });
    rrAccessRequest.value.entityName = "Organization";

    const drAccessRequest = ref({ entityName: "", requestText: "" });
    drAccessRequest.value.entityName = "Facility Site";

    return {
      noLabel,
      navigationItems,
      isExternalAuthenticated,
      isInternal,
      isBureauOrHd,
      userRef,
      isAuthenticated,
      drAccessRequest,
      rrAccessRequest,
      BaseButton,
      viewerStore,
      hiTopoJson,
      // getFacilitySites,
    };
  },
  components: {
    SkeletonButton,
    BaseButton,
    // LayoutAppContent,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    SimpleMap,
  },
});
</script>
