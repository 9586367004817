import { viewerColors } from "@mui/components/viewer";

// Example of sharing viewer config within an app.
export const group1 = { colors: viewerColors[0] };
export const group2 = { colors: viewerColors[1] };
export const group3 = { colors: viewerColors[2] };
export const group4 = { colors: viewerColors[3] };
export const group5 = { colors: viewerColors[4] };
export const group6 = { colors: viewerColors[5] };
export const group7 = { colors: viewerColors[6] };
export const group8 = { colors: viewerColors[7] };

export const metadataContext = {
  1: { isIncluded: true, group: group1 },
  2: { isIncluded: true, group: group2 },
  3: { isIncluded: true, group: group3 },
  4: { isIncluded: true, group: group4 },
  // Example -
  // 4: { isIncluded: false, group: group3 },
  5: { isIncluded: true, group: group5 },
  6: { isIncluded: true, group: group6 },
  7: { isIncluded: true, group: group7 },
};

export const additionalMetadata = [
  // Example -
  //   {
  //     id: 5,
  //     entity: "entityFive",
  //     sourceSystem: "sourceFive",
  //     subCategory: "subCatFive",
  //     label: "Air Pollution Sources",
  //     description: "example desc. 5",
  //   },
];
