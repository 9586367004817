import { ref, reactive, computed, watch, Ref } from "vue";
import apiService, { ApiOptions } from "@/utils/api/apiService.js";
// import { apiHosts } from "@/api/constants/apiHosts";
import { useUser } from "@/utils/auth/authentication/useUser.js";
import axios, { CreateAxiosDefaults } from "axios";
import { useAppSettingsStore } from "@/components/common/index.js";
import type { INotifierConfig, notifyErrorCb, notifySuccessCb } from "@/components/notifications/index.js";
import type { IUser } from "@/utils/user/index.js";

export interface IBffOptions {
  baseURL: string;
  loginUrl: string;
  logoutUrl: string;
  registerUrl: string;
  userUrl: string;
}

export interface IBffAuthn {
  redirectToLogin: () => void;
  redirectToLogout: () => void;
  redirectToRegister: () => void;
  userRef: Ref<{ [key: string | number | symbol]: any }>;
}

const defaultAxiosOptions = {
  baseURL: window.location.origin,
};

let defaultOptions: IBffOptions = {
  ...defaultAxiosOptions,
  loginUrl: "/auth/login",
  logoutUrl: "/auth/logout",
  registerUrl: "Not Yet Initialized",
  userUrl: "/api/auth/user",
};

const setDefaultOptions = () => {
  const appSettingsStore = useAppSettingsStore();
  defaultOptions = {
    ...defaultAxiosOptions,
    loginUrl: appSettingsStore.viteBaseUrl + "auth/login",
    logoutUrl: appSettingsStore.viteBaseUrl + "auth/logout",
    registerUrl: "Not Yet Initialized",
    userUrl: appSettingsStore.viteBaseUrl + "api/auth/user",
  };
};

const api = apiService<IUser>(Symbol("AUTHN"), defaultAxiosOptions as CreateAxiosDefaults);

const getUser = async (options: IBffOptions, config: INotifierConfig = {}) => {
  const successNotifCb: notifySuccessCb = (options) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb: notifyErrorCb = (options) => {
    options.fetched = "auth session";
    config?.errorNotifCb?.(options);
  };

  var userAuth = await api.get(options.userUrl, {
    successNotifCb,
    errorNotifCb,
  });

  //TODO: error handling?
  return userAuth.data;
};

const addReturnUrl = (base: string) => {
  // make sure we redirect back to the current location
  return (base += "?returnUrl=" + encodeURIComponent(window.location.href));
};

export function useBffAuthn(options: IBffOptions): IBffAuthn {
  setDefaultOptions();
  let opts = { ...defaultOptions, ...options };
  const { setUser, userRef } = useUser();

  getUser(opts).then((user) => {
    setUser(user);
  });

  return {
    redirectToLogin: () => {
      window.location.href = addReturnUrl(opts.baseURL + opts.loginUrl + "/");
    },
    redirectToLogout: () => {
      window.location.href = opts.baseURL + opts.logoutUrl;
    },
    redirectToRegister: () => {
      window.location.href = opts.registerUrl;
    },
    userRef,
  };
}
