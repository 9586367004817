<template>
  <div class="p-4 break-inside-avoid-column" :class="itemGroup.className" v-show="itemGroup.isAuthorized">
    <div class="pl-8 pb-1 pr-4 font-bold" v-if="itemGroup.showLabel">
      {{ itemGroup.label }}
    </div>
    <template v-for="item in itemGroup.items" :key="item.name">
      <!--TODO: skeleton? -->
      <div class="flex group/item mt-1" v-if="item.isAuthorized">
        <div class="flex-none w-6 h-6 mr-2 group/star" :class="areFavoritesEnabled ? '' : 'invisible'">
          <StarIcon class="h-6 w-6 text-neutral-500 hidden group-hover/item:block group-hover/star:hidden"></StarIcon>
          <StarIconSolid class="h-6 w-6 text-neutral-500 hidden group-hover/star:block"></StarIconSolid>
        </div>
        <router-link :to="{ name: item.name }" v-slot="{ href, navigate, isActive }" custom>
          <DisclosureButton
            as="a"
            :href="href"
            @click="
              ($event) => {
                navigate($event);
              }
            "
            class="relative flex gap-x-6 rounded-lg"
            :class="$style.link"
            :aria-current="isActive ? 'page' : undefined"
          >
            <div class="min-w-max text-neutral-900">
              {{ item.label }}
            </div>
          </DisclosureButton>
        </router-link>
      </div>
    </template>
  </div>
  <SkeletonButton v-if="itemGroup.isAuthPending" class="mt-2" />
</template>

<script lang="ts">
// Use a function NavItem() to more strongly bind props?
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import { useAppNav, type AppNavItem, type AppNavItemGroup } from "@/components/common/useAppNav.js";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { StarIcon } from "@heroicons/vue/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/vue/24/solid";
import type { DeepReadonly, PropType, UnwrapNestedRefs } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNavItemGroup",
  components: { DisclosureButton, StarIcon, StarIconSolid, SkeletonButton },
  props: {
    /** The {@link AppNavItemGroup} to be rendered */
    itemGroup: {
      type: Object as PropType<DeepReadonly<UnwrapNestedRefs<AppNavItemGroup>>>,
      required: true,
    },
  },
  setup(props) {
    const { areFavoritesEnabled } = useAppNav();

    return { areFavoritesEnabled };
  },
});
</script>

<style module>
.link:hover:before {
  visibility: visible;
  width: 100%;
}
.link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  @apply bg-sky-300;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
.link:hover {
  transition: all 0.2s;
  text-decoration: none;
}
</style>
