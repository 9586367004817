<template>
  <GenericList v-bind="$props">
    <template
      #default="{
        apiStatus,
        dataRef,
        countLabel,
        isErrorOrEmpty,
        isStatusSuccess,
        isStatusError,
        infiniteLoadHandler,
        sortByQueryRef,
        entityLabelPlural,
        isDefaultState,
      }"
    >
      <!-- slot: headerActions -->
      <ListHeader
        class="col-span-12 min-h-0"
        v-bind="$attrs"
        :total-results-count="dataRef?.totalResultsCount"
        :count-label="countLabel"
      >
        <!-- @slot Slot for header actions -->
        <slot name="headerActions"></slot
      ></ListHeader>

      <ListFiltersAndData>
        <template #filters v-if="$slots.filters">
          <!-- @slot Slot for the filters -->
          <slot name="filters"></slot
        ></template>
        <template #default>
          <table class="min-w-full" :class="[isErrorOrEmpty ? `min-h-full` : ``]">
            <caption class="sr-only">
              {{
                entityLabelPlural
              }}
            </caption>
            <thead class="sticky top-0 z-10 shadow">
              <!-- @slot Slot for table headings (usually `ListTableSortColumn`/`ListTableActionColumn` wrapped in a `tr` element)-->
              <slot name="tableHeadings"></slot>
            </thead>
            <tbody v-if="isStatusError">
              <ListTableErrorRow />
            </tbody>
            <tbody v-if="dataRef?.totalResultsCount === 0 && isStatusSuccess">
              <tr>
                <ListNotFound />
              </tr>
            </tbody>
            <tbody v-if="!isStatusError" class="bg-white">
              <!-- @slot Slot for table row (content and click handling for the list data goes here)-->
              <slot name="tableRows" :items="dataRef?._items"></slot>
            </tbody>
          </table>
          <InfiniteLoading target="#scrollingTableSection" @infinite="infiniteLoadHandler" class="pt-px">
            <template #spinner>
              <div class="mb-2 mt-4 flex w-full place-content-center" v-if="!isStatusSuccess && !isStatusError">
                <FormKitIcon icon="spinner" class="h-8 w-8 animate-spin rounded-full bg-neutral-100 text-neutral-600" />
              </div>
            </template>
          </InfiniteLoading>
        </template>
      </ListFiltersAndData>
    </template>
  </GenericList>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/**
 * Component for a ListTable that handles errors, empty list data, and infinite loading.
 * This component can be used to render a list table for a parent entity or a child entity.
 */
export default { name: "ListTable" };
</script>

<script setup lang="ts" generic="R extends IPaging<PagedType<R>>, T extends IPaging<PagedType<T>>">
import type { IPaging, PagedType } from "@/utils/api";
import { AuthRequest } from "@/utils/auth/authorization";
import { FormKitIcon } from "@formkit/vue";
import InfiniteLoading from "v3-infinite-loading";
import type { RouteLocationRaw } from "vue-router";
import GenericList from "@/components/list/genericList/GenericList.vue";
import ListFiltersAndData from "@/components/list/genericList/ListFiltersAndData.vue";
import ListHeader from "@/components/list/genericList/ListHeader.vue";
import type { ListApi, ListSortByArray } from "@/components/list/genericList/index";
import ListNotFound from "@/components/list/listTable/ListNotFound.vue";
import ListTableErrorRow from "@/components/list/listTable/ListTableErrorRow.vue";
6;
const props = withDefaults(
  defineProps<{
    /** Label for the entity being used in the list table */
    entityLabel: string;
    /** Plural version of the `entityLabel`. If not provided, this will default to appending an "s" to the `entityLabel` */
    entityLabelPlural?: string;
    /** Api object responsible for populating the list table. `apiCall` property must be present. `config` and `additionalFilters` are optional */
    api: ListApi<R, T>;
    /** Route to the create screen for this entity  */
    createTo?: RouteLocationRaw;
    /** Request for authorizing if the create option is availble  */
    createToAuth?: AuthRequest;
    /** take for the backend call (how many results should be returned) */
    take?: number;
    /** An array of field names for the entity to use as the default sorting property */
    sortByDefault: ListSortByArray;
    /** Renders the listTable as a child list table */
    isChildList?: boolean;
  }>(),
  {
    isChildList: false,
  },
);
</script>

<style module></style>
