import { useAppSettingsStore } from "@/components/common/index.js";
import { definePrivateState } from "@/utils/helpers/definePrivateState.js";
import type { Coordinate } from "ol/coordinate.js";
import WKT from "ol/format/WKT.js";
import { fromLonLat, Projection } from "ol/proj.js";
import { acceptHMRUpdate } from "pinia";
import { computed, ref } from "vue";
import type { LayerSource, ZoomToOption, ZoomToOptionProp } from "@/components/viewer/index.js";

/** Config store for the viewer. Options should be set in Main.ts. All options are recommended, but at a minimum projection, center, zoomToOptions, baseLayerOptionsIds, and defaultBaseLayerId should be set. */
export const useViewerConfigStore = definePrivateState(
  "ViewerConfigStore",
  () => ({
    baseURL: null as string | null,
    apiHost: null as symbol | null,
    projection: null as string | null,
    center: [] as Coordinate,
    zoom: 8,
    zoomDuration: 750,
    zoomToOptions: [] as ZoomToOption[],
    baseLayerOptionsIds: [] as number[],
    additionalBaseLayers: [] as LayerSource[],
    defaultBaseLayerId: undefined as number | undefined,
    referenceLayers: [] as LayerSource[],
  }),
  (privateState) => {
    // state

    // getters
    /** Returns the baseURL. If none is set, it uses the appSettings.  */
    const baseURL = computed(() => {
      if (privateState.baseURL) return privateState.baseURL;
      const appSettingsStore = useAppSettingsStore();
      return appSettingsStore.baseURL;
    });

    /** Returns the apiHost. If none is set, it uses the appSettings.  */
    const apiHost = computed(() => {
      if (privateState.apiHost) return privateState.apiHost;
      const appSettingsStore = useAppSettingsStore();
      return appSettingsStore.apiHost;
    });

    /** Returns the projection. If none is set, it uses the "EPSG:3857".  */
    const projection = computed(() => {
      if (privateState.projection) {
        return privateState.projection;
      }
      return "EPSG:3857"; //default
    });

    /** Returns the center. This has no default and needs to be set.  */
    const center = computed(() => {
      return privateState.center;
    });

    /** Returns the zoom. If none is set, it defaults to 8.  */
    const zoom = computed(() => {
      return privateState.zoom;
    });

    /** Returns the zoomDuration in milliseconds. If none is set, it defaults to 750.  */
    const zoomDuration = computed(() => {
      return privateState.zoomDuration;
    });

    /** Returns the zoomToOptions. Default: []  */
    const zoomToOptions = computed(() => {
      return privateState.zoomToOptions;
    });

    /** Returns the baseLayerOptionsIds. Default: []  */
    const baseLayerOptionsIds = computed(() => {
      return privateState.baseLayerOptionsIds;
    });

    /** Returns the additionalBaseLayers. Default: []  */
    const additionalBaseLayers = computed(() => {
      return privateState.additionalBaseLayers;
    });

    /** Returns the defaultBaseLayerId. This has no default and needs to be set */
    const defaultBaseLayerId = computed(() => {
      return privateState.defaultBaseLayerId;
    });

    /** Returns the referenceLayers. Default: []  */
    const referenceLayers = computed(() => {
      return privateState.referenceLayers;
    });

    // actions
    /**
     * Sets the provided value to the `privateState.baseURL`
     * @param {string|null } val New value for the `baseURL`
     */
    function setBaseURL(val: string | null) {
      privateState.baseURL = val;
    }

    /**
     * Sets the provided value to the `privateState.apiHost`
     * @param {symbol|null } val New value for the `apiHost`
     */
    function setApiHost(val: symbol | null) {
      privateState.apiHost = val;
    }

    /**
     * Sets the provided value to the `privateState.projection`
     * @param {string|null } val New value for the projection
     */
    function setProjection(val: string | null) {
      privateState.projection = val;
    }

    /**
     * Sets the provided value to the `privateState.center`
     * Accepts both OpenLayers coordinates and lonLat coordinates
     * @param {Coordinate } val Coordinate value for the center in either LonLat or OL Coordinate system
     * @param {boolean} isLonLat Indicator if it is in lonLat format - required for determining if conversion to OL coordinate is needed.
     */
    function setCenter(val: Coordinate, isLonLat = false) {
      if (isLonLat && val) {
        privateState.center = fromLonLat(val);
      } else {
        privateState.center = val;
      }
    }

    /**
     * Sets the provided value to the `privateState.zoom`
     * @param {number } val New value for the zoom
     */
    function setZoom(val: number) {
      privateState.zoom = val;
    }

    /**
     * Sets the provided value to the `privateState.zoomDuration`
     * @param {number } val New value for the zoomDuration in milliseconds
     */
    function setZoomDuration(val: number) {
      privateState.zoomDuration = val;
    }

    /**
     * Sets the provided value to the `privateState.baseLayerOptionsIds`.
     * Used to specifiy which base layers from the base layer list should be included.
     * @param {number[]} val List of ids of layers to be included.
     */

    function setBaseLayerOptionsIds(val: number[]) {
      privateState.baseLayerOptionsIds = val;
    }

    /**
     * Sets the provided value to the `privateState.additionalBaseLayers`.
     * Used to allow the inclusion of base layers that are not predifined within MUI.
     * @param {LayerSource[]} val List of layers to be added
     */
    function setAdditionalBaseLayers(val: LayerSource[]) {
      privateState.additionalBaseLayers = val;
    }

    /** Sets the default base layer id. This id is used to determine which map to load initially.
     * Id provided must exist within the context of the selected predefined base layers or any additional layers provided.
     * @param {number} val Id of the layer chosen for default
     */
    function setDefaultBaseLayerId(val: number) {
      privateState.defaultBaseLayerId = val;
    }

    /**
     * Sets the provided value to the `privateState.referenceLayers`.
     * Used to allow the inclusion of reference layers.
     * @param {LayerSource[]} val List of reference layers to be added
     */
    function setReferenceLayers(val: LayerSource[]) {
      privateState.referenceLayers = val;
    }

    /**
     * Sets the provided value to the `privateState.zoomToOptions` while converting Long/Lat coords to OL coords.
     * @param { ZoomToOptionProp } val New value for the `zoomToOptions`
     */
    function setZoomToOptions(val: ZoomToOptionProp[]) {
      if (privateState.projection === null)
        throw new Error("Projection must be configured before configuring ZoomToOptions.");

      // const wkt = new WKT();
      privateState.zoomToOptions = val.map((x) => getZoomToOption(x));
      //  {
      //   const result = {
      //     geometry: wkt.readGeometry(x.wkt, {
      //       featureProjection: new Projection({ code: privateState.projection! }),
      //       dataProjection: new Projection({ code: "EPSG:4326" }),
      //     }),
      //     label: x.label,
      //     zoom: x.zoom,
      //   };

      //   return result;
      // });
    }

    /** Provided a zoomToOptionProp, this returns the zoomToOption
     * @param {ZoomToOptionProp} val ZoomToOptionProp that provide the wkt, label, minZoom, and maxZoom for the zoom to option
     */
    function getZoomToOption(val: ZoomToOptionProp) {
      const wkt = new WKT();

      const result = {
        geometry: wkt.readGeometry(val.wkt, {
          featureProjection: new Projection({ code: privateState.projection! }),
          dataProjection: new Projection({ code: "EPSG:4326" }),
        }),
        label: val.label,
        minZoom: val.minZoom,
        maxZoom: val.maxZoom,
      };

      return result;
    }

    return {
      //state

      //getters
      baseURL,
      apiHost,
      projection,
      center,
      zoom,
      zoomDuration,
      zoomToOptions,
      baseLayerOptionsIds,
      additionalBaseLayers,
      defaultBaseLayerId,
      referenceLayers,

      //actions
      setBaseURL,
      setApiHost,
      setProjection,
      setCenter,
      setZoom,
      setZoomDuration,
      setBaseLayerOptionsIds,
      setAdditionalBaseLayers,
      setDefaultBaseLayerId,
      setReferenceLayers,
      setZoomToOptions,
      getZoomToOption,
    };
  },
);

export type UseViewerConfigStore = ReturnType<typeof useViewerConfigStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useViewerConfigStore, import.meta.hot));
}
