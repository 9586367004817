import { Viewer } from "@mui/components/viewer";
import { RouterView } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  viewer: "Viewer",
  viewerTest: "ViewerTest",
};

export { names as viewerRouteNames };

const viewerRoutes = [
  {
    path: "/viewer",
    name: names.viewer,
    meta: { title: "Viewer" },
    component: {
      render: () => <Viewer />,
    },
  },
  {
    path: "/viewerTest",
    name: names.viewerTest,
    meta: { title: "Viewer Test" },
    component: () => import("@casa/views/viewer/ViewerTest.vue"),
  },
];
export default viewerRoutes;
