import { useApi, type IPaging, type IPagingApiAndNotifierConfig } from "@/utils/api/index.js";
import { acceptHMRUpdate, defineStore } from "pinia";
import {
  computed,
  reactive,
  ref,
  shallowRef,
  toRaw,
  unref,
  watch,
  type ComponentInstance,
  type Ref,
  type ShallowRef,
} from "vue";
import { useCssBreakpointStore } from "@/components/common/cssBreakpointStore.js";
import {
  DATA_STATUS,
  ViewerDataset,
  featureFromILocation,
  getMapPin,
  viewerColors,
  type DatasetMetadata,
  type DatasetPayload,
  type LayerSource,
  type LayerSourceValue,
  type ViewerDatasetGroup,
  type ViewerDatasetProp,
  type ViewerResult,
  type ZoomToOption,
  type ZoomToOptionProp,
} from "@/components/viewer/index.js";
import { useViewerConfigStore } from "@/components/viewer/viewerConfigStore.js";

import { definePrivateState } from "@/utils/helpers/definePrivateState.js";
import axios from "axios";
import Feature, { type FeatureLike } from "ol/Feature.js";
import olMap from "ol/Map.js";
import View, { type FitOptions } from "ol/View.js";
import type { Coordinate } from "ol/coordinate.js";
import { boundingExtent } from "ol/extent.js";
import EsriJSON from "ol/format/EsriJSON.js";
import WKT from "ol/format/WKT.js";
import Geometry from "ol/geom/Geometry.js";
import LineString from "ol/geom/LineString.js";
import MultiPoint from "ol/geom/MultiPoint.js";
import Point from "ol/geom/Point.js";
import Polygon from "ol/geom/Polygon.js";
import BaseLayer from "ol/layer/Base.js";
import LayerGroup from "ol/layer/Group.js";
import TileLayer from "ol/layer/Tile.js";
import VectorLayer from "ol/layer/Vector.js";
import { tile } from "ol/loadingstrategy.js";
import type { Pixel } from "ol/pixel.js";
import { Projection, fromLonLat, useGeographic } from "ol/proj.js";
import Cluster from "ol/source/Cluster.js";
import TileArcGISRest from "ol/source/TileArcGISRest.js";
import VectorSource from "ol/source/Vector.js";
import XYZ from "ol/source/XYZ.js";
import Circle from "ol/style/Circle.js";
import Fill from "ol/style/Fill.js";
import Icon from "ol/style/Icon.js";
import Stroke from "ol/style/Stroke.js";
import Style, { type StyleFunction, type StyleLike } from "ol/style/Style.js";
import Text from "ol/style/Text.js";
import { createXYZ } from "ol/tilegrid.js";
import type { LocationQuery, Router } from "vue-router";
import type { NotifyOptionsError } from "@/components/notifications/index.js";
import type { IDataset } from "@/components/viewer/dataset.js";
import { getDatasets } from "@/components/viewer/datasetApi.js";
import type { ILocation } from "@/components/viewer/location.js";
import { getLocationById, getPinLocations } from "@/components/viewer/locationApi.js";
import predefinedBaseLayers from "@/components/viewer/predefinedBaseLayers.js";

// const projWGS84 = new Projection({ code: "EPSG:4326" });
// const proj900913 = new Projection({ code: "EPSG:900913" });

export const useViewerStore = definePrivateState(
  "ViewerStore",
  () => ({
    showPoints: true,
    showLines: true,
    showPolygons: true,
    dataStatus: DATA_STATUS.noMeta,
    datasetsPromise: new Promise(() => {}) as Promise<IPaging<IDataset> | undefined>,
    currentLocationId: undefined as number | undefined,
    currentLocation: undefined as ILocation | undefined,
    rejectGetLocation: (() => {}) as (reason?: any) => void,
    selectedFeature: undefined as FeatureLike | undefined,
    selectedFeatureLayer: undefined as VectorLayer | undefined,
    pointStyle: new Map<Icon, Style[]>(),
    nonPointStyle: new Map<Icon, Style[]>(),
    wkt: new WKT(),
    hasReferenceLayers: false,
  }),
  (privateState) => {
    const cssBreakpointStore = useCssBreakpointStore();
    const viewerConfigStore = useViewerConfigStore();

    // state
    const theMap = shallowRef<olMap>();
    const showSidebar = ref(true);
    const showDetails = ref<"hide" | "show" | "maximize">("show"); // hide, show, maximize
    const previousPane = ref<"map" | "sidebar">("map");
    const currentSidebarPanel = ref<"list" | "datasets" | "layers" | "help">("list"); // list, datasets, layers, help
    const hasActiveTools = ref(false);

    const datasets = ref<Map<number, ViewerDataset>>(new Map()) as Ref<Map<number, ViewerDataset>>; //TS stupidity to make the ref types right.
    const dataProjection = ref("EPSG:4326"); // WGS84 projection used by our data in storage
    const featureProjection = ref("EPSG:900913"); // "Google" web mercator projection

    const projection = ref(viewerConfigStore.projection);
    const centerCoordinates = ref(viewerConfigStore.center); //no default
    const zoom = ref(viewerConfigStore.zoom);

    const allDatasetIds = ref<number[]>([]);
    const datasetMetadata = ref<DatasetMetadata[]>([]) as Ref<DatasetMetadata[]>; // | ViewerDatasetProp[]
    const metadataContext = ref<{ [key: number]: { isIncluded: boolean; group: ViewerDatasetGroup } }>();
    const additionalMetadata = ref<DatasetMetadata[]>([]) as Ref<DatasetMetadata[]>;

    const syncedQueryRef = ref();
    const datasetPayloadRef = ref<DatasetPayload[]>([]) as Ref<DatasetPayload[]>;
    const activeDatasetQueries = ref<number[]>([]);

    const preDefinedBaseLayers: LayerSource[] = predefinedBaseLayers;

    const baseLayers = ref<LayerSource[]>([]) as Ref<LayerSource[]>;
    const referenceLayerGroup = ref<LayerGroup>(new LayerGroup({ layers: [] })) as Ref<LayerGroup>;
    const currentReferenceLayers = ref(new Map());

    const referenceLayers = shallowRef<Map<number, LayerSource>>();
    const allLayerGroups = shallowRef<LayerGroup[]>([]) as ShallowRef<Array<InstanceType<typeof LayerGroup>>>;

    const programmaticResetPoints = ref(false);
    const programmaticResetPoly = ref(false);
    const programmaticResetLines = ref(false);

    const progUpdateMap = ref<Map<number, boolean>>(new Map());

    // getters
    const preDefinedBaseLayersIds = computed(() => preDefinedBaseLayers.map((layer) => layer.source.id));
    const showPoints = computed(() => privateState.showPoints);
    const showLines = computed(() => privateState.showLines);
    const showPolygons = computed(() => privateState.showPolygons);
    const dataStatus = computed(() => privateState.dataStatus);

    const currentLocation = computed(() => privateState.currentLocation);
    const currentLocationId = computed(() => privateState.currentLocationId);
    const currentDatasetLabel = computed(() =>
      currentLocation.value ? datasets.value.get(currentLocation.value.datasetIdentifier)?.label : "",
    );

    const isMobileMode = computed(
      () => cssBreakpointStore.isMobile || cssBreakpointStore.isSm || cssBreakpointStore.isMd,
    );

    const hasReferenceLayers = computed(() => privateState.hasReferenceLayers);

    // actions
    const { exec: fetchDatasets } = useApi(getDatasets);
    const { exec: getPins } = useApi(getPinLocations);

    /** Runs all required initial set up for the viewer. Fetches datasets, runs the initialize function, fills the map, adds the click event, and filters datasets.
     * @param {olMap} newMap The map the we need to add the layers and view to.
     * @param {Ref<number[]>} datasetsQueryRef The current dataset query ref. Used to filter out datasets not currently active.
     * @param {LayerGroup} baseLayerGroup The base layer group object that needs to be added to the map.
     */
    function fill(newMap: olMap, datasetsQueryRef: Ref<number[]>, baseLayerGroup: LayerGroup) {
      initialize(baseLayerGroup);
      newMap.setLayers(allLayerGroups.value);
      newMap.setView(
        new View({
          projection: projection.value,
          center: centerCoordinates.value, //lon/lat converted to coordinate system
          zoom: zoom.value,
          maxZoom: 18, // TODO: add support for externally specifying this?
        }),
      );

      // The rest can't be run until the dataset metadata has been hydrated.
      privateState.datasetsPromise.then((result) => {
        if (!result) return;

        theMap.value = newMap;

        // Select a feature on the map
        theMap.value.on("click", function (evt) {
          if (hasActiveTools.value || evt.dragging) return;

          selectFeatureAtPixel(evt.pixel);
        });

        theMap.value.getView().on("change:resolution", function (evt) {
          var view = evt.target;

          const zoom = view.getZoom();
          const maxZoom = view.getMaxZoom();
          datasets.value.forEach((set) => {
            var source = set.layer?.getSource();
            if (source instanceof Cluster) {
              var distance = source.getDistance();
              if (zoom >= maxZoom && distance > 0) {
                source.setDistance(0);
              } else if (zoom < maxZoom && distance == 0) {
                source.setDistance(40);
              }
            }
          });
        });

        /**
         * Unfortunately adding the hover features (like changing the cursor or highlighting an item) makes the map unusable due to poor performance.
        // Hover over a feature on the map
        // theMap.value.on("pointermove", function (evt) {
        //   if (evt.dragging) {
        //     return;
        //   }

        // const pixel = theMap.value!.getEventPixel(evt.originalEvent);
        // const hit = theMap.value!.hasFeatureAtPixel(pixel);
        // theMap.value!.getTargetElement().style.cursor = hit ? "pointer" : "";

        // hoverFeatureAtPixel(pixel);
        // });
         *  */

        let datasetIds = [...allDatasetIds.value];

        if (datasetsQueryRef.value.length > 0) {
          datasetIds = datasetIds.filter((id) => datasetsQueryRef.value.includes(id));
        } else {
          datasetsQueryRef.value = [...datasetIds];
        }
        activeDatasetQueries.value = [...datasetIds];

        // Updates datasetPayloadRef
        getQueryFromMeta();

        addDatasets(datasetMetadata.value as ViewerDatasetProp[]); //todo: ts

        datasets.value.forEach((set) => {
          set.isVisible = set.isVisible && datasetIds.includes(set.id);
          progUpdateMap.value.set(set.id, false);
        });

        privateState.dataStatus = DATA_STATUS.hasDatasets;
      });
    }

    /** Accepts a pixel and finds the feature located at that position. Passes this feature to the selectFeature() function.
     * @param {Pixel} pixel Pixel where feature is located at.
     */
    function selectFeatureAtPixel(pixel: Pixel) {
      /*
        //TODO: This is how you grab multiple stacked features from a single click.
        //       Restore this in the future to work toward feature disambiguation.
        let count = 1;
        const features = new Array<FeatureLike>();
        aMap.forEachFeatureAtPixel(evt.pixel, function (feature) {
          console.log(count++, feature);
          features.push(feature);
        }); // TODO: This supports filtering to limit it to specific layers - do we need to use that?
        console.log(features);
        */

      // TODO: Filter this to only include layers we have feature data on.
      const featureResult = theMap.value!.forEachFeatureAtPixel(pixel, function (feature) {
        // skip over reference values.
        if (feature.get("datasetIdentifier") === undefined && (feature.get("features")?.length ?? 0) === 0) return;
        // Returning a truthy value causes the "forEach" to stop on the first value.
        return feature;
      });
      if (featureResult) {
        const features = featureResult.get("features");
        const view = theMap.value!.getView();
        const zoom = view.getZoom();
        const maxZoom = view.getMaxZoom();

        if (features) {
          if (features.length > 1 && zoom !== maxZoom) {
            // It's a cluster, so zoom to it.
            const extent = boundingExtent(features.map((f: Feature<Geometry>) => f.getGeometry()?.getExtent()));
            theMap.value?.getView().fit(extent, { padding: [75, 75, 75, 75], duration: 500 });
          } else {
            //TODO: Add support here for disambiguation when one point has multiple features.
            // it's a single feature on the cluster layer, so select it.
            selectFeature(features[0]);
          }
        } else {
          // it's a single feature, so select it.
          selectFeature(featureResult);
        }
      }
    }

    /** Accepts a feature and assigns it to the privateState.selectedFeature. Updates the location when appropiate
     * @param {FeatureLike | undefined} feature Potential feature to be added.
     * @param {boolean} withLocationUpdate Indicator if location should also be updated. Defaults to true.
     */
    function selectFeature(feature: FeatureLike | undefined, withLocationUpdate = true) {
      if (feature !== privateState.selectedFeature) {
        if (privateState.selectedFeature) {
          privateState.selectedFeatureLayer!.getSource()?.removeFeature(privateState.selectedFeature);
        }
        if (feature) {
          privateState.selectedFeatureLayer!.getSource()?.addFeature(feature);
          withLocationUpdate && setCurrentLocationUsingFeature(feature);
        } else {
          privateState.currentLocation = undefined;
          privateState.currentLocationId = undefined;
        }
        privateState.selectedFeature = feature;
      }
    }

    /** Resets the values for the layers (points, lines, polygons), the datasets, and the keywords back to their default values.
     * Currently defaults to showing all layers, all datasets, and no keywords.
     * @param {Router} router
     * @param {LocationQuery} query
     */
    function reset(router: Router, query: LocationQuery) {
      const actingDefaultValue = true; //TODO: This may change from 'true' to a default setting configured by app in the future. Right now, default is assumed to be true.

      if (privateState.showPoints != actingDefaultValue) {
        programmaticResetPoints.value = true;
        privateState.showPoints = actingDefaultValue;
      }

      if (privateState.showPolygons != actingDefaultValue) {
        programmaticResetPoly.value = true;
        privateState.showPolygons = actingDefaultValue;
      }

      if (privateState.showLines != actingDefaultValue) {
        programmaticResetLines.value = true;
        privateState.showLines = actingDefaultValue;
      }

      updateLayersVisibility();

      //IMPORTANT - this reset assumes we want to start with all datasets.

      datasets.value.forEach((set) => {
        //only change if its not already true. important for the programmatic part.
        if (!set.isVisible) {
          set.isVisible = true;
          progUpdateMap.value.set(set.id, true);
        }
      });

      //TODO: I think I need these?
      activeDatasetQueries.value = [...allDatasetIds.value];
      getQueryFromMeta();

      let updatedQuery = { ...query };
      updatedQuery["ds"] = [...allDatasetIds.value] as any; //.map(String);

      if (updatedQuery["keywords"]) {
        delete updatedQuery["keywords"];
      }

      router.replace({ query: updatedQuery });
    }

    /** Function to run on app start. Verifies necessary configuration was provided. Sets up base and reference layer groups.
     * @param {LayerGroup} baseLayerGroup base layer group for the map.
     */
    function initialize(baseLayerGroup: LayerGroup) {
      //verify center coordinates were provided
      if (centerCoordinates.value.length < 2) {
        throw new Error("Center coordinates must be defined in the Viewer Config Store");
      }

      if (viewerConfigStore.baseLayerOptionsIds.length === 0 && viewerConfigStore.additionalBaseLayers.length === 0) {
        //and no other options were provided
        throw new Error("At least one base layer must be provided.");
      }

      //it's possible that there are already layergroups in there, but there shouldn't be, so erase them.
      allLayerGroups.value.splice(0);
      baseLayerGroup.getLayers()?.clear();

      allLayerGroups.value.push(baseLayerGroup);

      validateBaseLayerIds();
      getBaseLayersOptions();

      updateBaseLayer(viewerConfigStore.defaultBaseLayerId, baseLayerGroup);

      if (viewerConfigStore.referenceLayers.length > 0) {
        validateRefLayerIds();
        privateState.hasReferenceLayers = true;
        refLayerToMap();
        updateReferenceLayers();
        allLayerGroups.value.push(referenceLayerGroup.value);
      }
    }

    /** Function to go out and fetch the metadata from the BE and then assign them to their groups.
     * @param {boolean} isForced Indicator that allows for the function to run even if the DATA_STATUS is not in a desired state. (Forcing the run). Defaults to false.
     */
    function hydrateDatasetMetadata(isForced: boolean = false) {
      if (privateState.dataStatus === DATA_STATUS.noMeta || isForced)
        privateState.datasetsPromise = fetchDatasets().then((result) => {
          if (!result) return;

          datasetMetadata.value = result._items;

          // Default to all datasets; the query params may override that below
          allDatasetIds.value = result._items
            .filter((item) => metadataContext.value?.[item.id]?.isIncluded)
            .map((item) => item.id);

          // activeDatasetQueries.value = result._items.map((item) => item.id);
          if (additionalMetadata.value.length > 0) {
            additionalMetadata.value.forEach((item) => {
              datasetMetadata.value.push(item);
              if (metadataContext.value?.[item.id]?.isIncluded) allDatasetIds.value.push(item.id);
            });
          }
          datasetMetadata.value = assignDatasetLettersAndGroups();

          return result;
        });
    }

    //to-do make center private state?
    /** Sets the center coordinates. Accepts both OL coordinate style or LonLat
     * @param {Coordinate} val Coordinate desired for the center.
     * @param {boolean} isLonLat Indicator the provided coordinate is in LonLat format so that necessary conversions can take place.
     */
    function setCenterCoordinates(val: Coordinate, isLonLat = false) {
      if (isLonLat && val) {
        centerCoordinates.value = fromLonLat(val);
      } else {
        centerCoordinates.value = val;
      }
    }

    /** Accepts a feature and sets the current location
     * @param {FeatureLike} val Feature to get id and zoomToOption from and then set to current location
     */
    function setCurrentLocationUsingFeature(val: FeatureLike) {
      const id = val.get("id");
      const to: ZoomToOption = { geometry: val.getGeometry() as Geometry, label: "", maxZoom: 18 }; //dirty but we only create features using Geometry
      setCurrentLocation(id, to, val);
    }

    /** Accepts a Location and sets the current location
     * @param {ILocation} val Location object to be set to current.
     */
    function setCurrentLocationUsingILocation(val: ILocation) {
      const zoomToOptionProp: ZoomToOptionProp = {
        wkt: val.geometryWkt,
        label: val.title,
        maxZoom: 18,
      };
      setCurrentLocation(val.id, viewerConfigStore.getZoomToOption(zoomToOptionProp), undefined, val.datasetIdentifier);
    }

    /** Sets the current location using id, zoomToOption and possible Feature and/or datasetIdentifier
     * @param {number} id Id of the location
     * @param {ZoomToOption} to ZoomToOption for the location
     * @param {FeatureLike} feature Optional feature to use to set the location. Dataset identifier is grabbed from this if not provided.
     * @param {number} datasetIdentifier Optional datasetIdenitifer for location. If not provided, feature should be.
     */
    function setCurrentLocation(id: number, to: ZoomToOption, feature?: FeatureLike, datasetIdentifier?: number) {
      if (privateState.currentLocation) {
        privateState.currentLocation = undefined;
      }

      const zoomToLocation = Boolean(!feature);
      const { exec: getLocation } = useApi(getLocationById);

      const getLocationPromise = new Promise<ILocation>((resolve, reject) => {
        privateState.rejectGetLocation();
        privateState.rejectGetLocation = reject;
        getLocation(id).then((result) => {
          // Check the id as well because it's possible for the user to deslect the location before the results come back.
          // TODO: Eventually cancel the request rather than make this check here.
          if (result && result.id === privateState.currentLocationId) resolve(result);
          reject();
        });
      });

      getLocationPromise.then(
        (result) => {
          result.datasetIdentifier = datasetIdentifier ? datasetIdentifier : feature?.get("datasetIdentifier");
          privateState.currentLocation = result;
        },
        () => {}, //ignore the rejection
      );

      privateState.currentLocationId = id;
      if (!feature && datasetIdentifier) {
        const set = datasets.value.get(datasetIdentifier);
        if (!set) return;

        let layer: VectorLayer<VectorSource<Feature<Geometry>>, Feature<Geometry>> | undefined;
        switch (to.geometry.getType()) {
          case "Point":
            layer = set.layer;
            break;
          case "Polygon":
            layer = set.polyLayer;
            break;
          case "LineString":
            layer = set.lineLayer;
            break;
        }

        //TODO: Consider storing the features in an external collection that we control on the dataset and then tell openlayers about.
        const layerFeatures = layer?.getSource()?.getFeatures();
        if (layerFeatures === undefined) return;

        let feature;
        for (let key in layerFeatures) {
          let feat = layerFeatures[key];
          if (feat.get("id")) {
            if (feat.get("id") === id) {
              feature = feat;
              break;
            }
          } else {
            feature = feat.get("features").find((x: Feature<Geometry>) => x.get("id") === id);
            if (feature) break;
          }
        }
        selectFeature(feature, false);
      }

      if (zoomToLocation) zoomTo(to);
    }

    /** Turns the array of active dataset ids into a query object the BE understands. */
    function getQueryFromMeta() {
      datasetPayloadRef.value = datasetMetadata.value
        .filter((item) => activeDatasetQueries.value.includes(item.id))
        .map((item) => ({
          identifier: item.id,
          entity: item.entity,
          sourceSystem: item.sourceSystem,
          subCategory: item.subCategory,
        }));
    }

    /** Function to add the groups/letters to the metadata  */
    function assignDatasetLettersAndGroups() {
      //** Assumes we never have more than 26 metadata objects **
      if (!metadataContext.value) {
        throw new Error("Meta data context must be provided to properly group data.");
      }

      const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
      let letterIndex = 0;
      const appendedMeta: ViewerDatasetProp[] = [];
      datasetMetadata.value.forEach((item) => {
        if (metadataContext.value?.[item.id]?.isIncluded) {
          appendedMeta.push({
            ...item,
            datasetGroup: metadataContext.value[item.id].group,
            letter: alpha[letterIndex++],
          } as ViewerDatasetProp); //todo: ts
        }
      });

      return appendedMeta;
    }

    /** Transforms ViewerDatasetProps into ViewerDatasets. Generates a map with these datasets and assigns it to the `datasets` ref.
     * @param {ViewerDatasetProp[]} propSets sets to be transformed into ViewerDatasets
     */
    function addDatasets(propSets: ViewerDatasetProp[]) {
      const datasetMap = new Map<number, ViewerDataset>();

      propSets.forEach((set) => {
        datasetMap.set(set.id, {
          id: set.id,
          label: set.label,
          description: set.description ?? "",
          isVisible: set.isVisible ?? true,
          datasetGroup: set.datasetGroup,
          fillAndStroke: new Style({
            fill: new Fill({
              color: set.datasetGroup.colors.fill,
            }),
            stroke: new Stroke({
              color: set.datasetGroup.colors.background,
              width: 1,
            }),
          }),
          pinIcon: new Icon({
            opacity: 1,
            src: "data:image/svg+xml;base64," + btoa(getMapPin(set.datasetGroup.colors)),
            scale: 1,
            anchor: [0.5, 1],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
          }),
          results: set.results ?? [],
        });
      });

      datasets.value = datasetMap;
    }

    /** Returns the appropiate style. Adds icon to privateState.nonPointStyle map if not pre-existing.
     * @param {Style} fillAndStroke Fill and Stroke style that is combined with the generated points style
     * @param {Icon} icon Icon for the style. Used to get the existing one from private state. If it doesn't exist, its used to create the new one.
     * @param {Object} options Accepts an object type a type property. If set to select, the function will enlarge the icon. Defaults to { type: undefined }
     */
    function getStyle(fillAndStroke: Style, icon: Icon, options: { type: undefined | "select" } = { type: undefined }) {
      let nonPS = privateState.nonPointStyle.get(icon);

      if (!nonPS) {
        const points = new Style({
          // image: icon,
          geometry: function (feature) {
            if (!feature) return;

            const fg = feature.getGeometry();
            //these are needed to be able to include the pins for lines and polygons.
            if (fg instanceof Polygon) {
              // return the coordinates of the first ring of the polygon
              const coordinates = fg?.getCoordinates()[0];
              return new MultiPoint(coordinates);
            } else if (fg instanceof LineString) {
              const coordinates = fg?.getCoordinates();
              return new MultiPoint(coordinates);
            }
            // fallback to returning point geometry
            return fg;
          },
        });
        nonPS = [fillAndStroke, points];
        privateState.nonPointStyle.set(icon, nonPS);
      }

      if (options.type === "select") {
        const points = nonPS[1].clone();
        icon = icon.clone();
        icon.setScale(2);
        points.setImage(icon);
        return [nonPS[0], points];
      }

      return nonPS;
    }

    /** Takes in a feature and generates the appropiate style for it based on its datasetIdentifier and fillAndStroke/pinIcon combination. */
    function selectStyle(feature: FeatureLike) {
      const fillAndStroke = getDataset(feature.get("datasetIdentifier")).fillAndStroke;
      const pinIcon = getDataset(feature.get("datasetIdentifier")).pinIcon;
      const newStyle = getStyle(fillAndStroke, pinIcon, { type: "select" });

      return newStyle;
    }

    // function hoverStyle(feature: FeatureLike) {
    //   const fillAndStroke = getDataset(feature.get("datasetIdentifier")).fillAndStroke;
    //   const pinIcon = getDataset(feature.get("datasetIdentifier")).pinIcon;
    //   const newStyle = getStyle(fillAndStroke, pinIcon, { type: "hover" }); //feature.get("geometry"),

    //   return newStyle;
    // }

    /** Accepts an array of locations, and then sorts them into points, lines, and polygons before generating a VectorSource for each of them
     * @param {ILocation[]} results Locations to be iterated through
     * @returns {[VectorSource<Feature<Point>>, VectorSource<Feature<LineString>>, VectorSource<Feature<Polygon>>]} Three vector sources, one for each location type.
     */
    function getVectorSource(
      results: ILocation[],
    ): [Cluster<Feature<Point>>, VectorSource<Feature<LineString>>, VectorSource<Feature<Polygon>>] {
      //arrays to hold the three different types so we can correctly assign them to two different layers
      const points: Feature<Point>[] = [];
      const lines: Feature<LineString>[] = [];
      const polygons: Feature<Polygon>[] = [];

      results.forEach((x) => {
        //getting feature
        const wktGeometry = privateState.wkt
          .readFeature(x.geometryWkt, {
            featureProjection: new Projection({ code: projection.value }),
          })
          .getGeometry();
        let convertedGeometry;

        //logic to handle points
        if (wktGeometry instanceof Point) {
          let pointGeometry = wktGeometry;

          //create a new Point from the converted lon and lat coordinates => add point to feature => add to corresponding array
          convertedGeometry = new Point(fromLonLat(pointGeometry?.getCoordinates()));
          if (convertedGeometry) {
            points.push(featureFromILocation(x, convertedGeometry));
          }
        }
        //logic to handle lines
        else if (wktGeometry instanceof LineString) {
          let lineGeometry = wktGeometry;
          let originalCoords = lineGeometry?.getCoordinates();
          let newCoords: Coordinate[] = [];

          //iterate through the coordinates => convert to openLayers coordinate system => use to create new LineString => add LineString to feature => add to corresponding array
          originalCoords.forEach((coord) => {
            newCoords.push(fromLonLat(coord));
          });

          convertedGeometry = new LineString(newCoords);
          if (convertedGeometry) {
            lines.push(featureFromILocation(x, convertedGeometry));
          }
        }
        //logic to handle polygons
        else if (wktGeometry instanceof Polygon) {
          let geometry = wktGeometry;
          let origCoords: Coordinate[] = geometry?.getCoordinates()[0];
          let newCoords: Coordinate[][] = [[]];

          //iterate through the coordinates => convert to openLayers coordinate system => use new coordinates to create new Polygon => add Polygon to feature => add to corresponding array
          //Note: polygons have an extra array - format [[ [l,l], [l,l], [l,l] ]]
          for (let i = 0; i < origCoords.length; i++) {
            newCoords[0].push(fromLonLat(origCoords[i]));
          }

          convertedGeometry = new Polygon(newCoords);
          if (convertedGeometry) {
            polygons.push(featureFromILocation(x, convertedGeometry));
          }
        }
      });

      const clusterSource = new Cluster({
        distance: 40,
        minDistance: 30,
        source: new VectorSource({ features: points }),
      });
      const pointSource = clusterSource; //new VectorSource({ features: points });
      const lineSource = new VectorSource({ features: lines });
      const polySource = new VectorSource({ features: polygons });

      return [pointSource, lineSource, polySource];
    }

    let abortSearch: Function | null = null;
    const getPinsHandler = async (params: any) => {
      abortSearch?.();

      const config: IPagingApiAndNotifierConfig = {
        abortMethod: (abort: Function) => {
          abortSearch = abort;
        },
        errorNotifCb: (options: NotifyOptionsError) => {
          options.hideStatusCodes = [422];
        },
      };
      return getPins(params, config)
        .then((response) => {
          if (!response) return [];

          response._items.forEach((item) => {
            if (item.datasetIdentifier) {
              let myDataset = datasets.value?.get(item.datasetIdentifier);
              myDataset?.results.push(item);
            }
          });
        })
        .catch((error) => {
          console.warn("Cannot get locations without a DatasetRequest object", error);
          return [];
        });
    };

    /** Performs the location search. Updates the map to match new results.
     */
    function doSearch(query?: any) {
      const searchWatch = watch(
        () => dataStatus.value,
        (newVal, oldVal) => {
          if (
            newVal === DATA_STATUS.hasDatasets ||
            newVal === DATA_STATUS.hasSearch ||
            newVal === DATA_STATUS.hasLayers
          ) {
            resetDatasetResults();
            removeLayers();

            let params;

            if (query) {
              params = { ...query };
            } else {
              params = { ...syncedQueryRef.value };
            }

            if (params.take) delete params["take"];
            if (params.skip) delete params["skip"];

            getPinsHandler(params).then(() => {
              createLayers();

              searchWatch();
              privateState.dataStatus = DATA_STATUS.hasSearch;

              addLayers();
            });
          } else {
            return;
          }
        },
        { immediate: true },
      );
    }

    /** Clears the results for each dataset. */
    function resetDatasetResults() {
      datasets.value.forEach((set) => {
        set.results = [];
      });
    }

    /** Returns point style based on fill and stroke and icon
     * @param {Style} fillAndStroke Fill and Stroke Style
     * @param {Icon} icon Icon
     */
    function getPointStyle(fillAndStroke: Style, icon: Icon) {
      let style = privateState.pointStyle.get(icon);

      if (!style) {
        const imageStyle = new Style({
          image: icon,
        });

        style = [fillAndStroke, imageStyle];
        privateState.pointStyle.set(icon, style);
      }

      return style;
    }

    /** Iterates through the datasets and then uses their location results to generate the three layers needed (points, lines, polygons) */
    function createLayers() {
      datasets.value.forEach((set) => {
        const pointStyle = getPointStyle(set.fillAndStroke, set.pinIcon);
        const nonPointStyle = getStyle(set.fillAndStroke, set.pinIcon);

        const [pointSource, lineSource, polySource] = getVectorSource(set.results);
        set.layer = new VectorLayer({
          source: pointSource,
          style: (feature, resolution) => {
            const size = feature.get("features").length;
            const view = theMap.value!.getView();
            const zoom = view.getZoom();
            const maxZoom = view.getMaxZoom();

            if (zoom === maxZoom || size <= 1) {
              // Render individual points at max zoom or when there's a single point.
              return pointStyle;
            } else {
              // Render as Clusters
              return new Style({
                image: new Circle({
                  radius: 18,
                  fill: new Fill({ color: set.datasetGroup.colors.background }),
                  stroke: new Stroke({ color: set.datasetGroup.colors.foreground, width: 1 }),
                }),
                text: new Text({
                  text: size.toString(),
                  fill: new Fill({ color: set.datasetGroup.colors.foreground }),
                  font: "12px sans-serif",
                }),
              });
            }
          },
        });
        set.lineLayer = new VectorLayer({ source: lineSource, style: nonPointStyle });
        set.polyLayer = new VectorLayer({ source: polySource, style: nonPointStyle });
      });

      // Create the selection layer so it's on top
      privateState.selectedFeatureLayer = new VectorLayer({
        source: new VectorSource(),
        map: theMap.value,
        style: selectStyle,
      });
    }

    /** Iterating through the datasets and adding their layers to the map */
    function addLayers() {
      datasets.value?.forEach((set) => {
        if (set.lineLayer instanceof BaseLayer) {
          theMap.value!.addLayer(set.lineLayer);
        }
        if (set.polyLayer instanceof BaseLayer) {
          theMap.value!.addLayer(set.polyLayer);
        }
      });

      // Do this separately so that the point layers are rendered on top of polygons and linestrings.
      datasets.value?.forEach((set) => {
        if (set.layer instanceof BaseLayer) {
          theMap.value!.addLayer(set.layer);
        }
        updateLayerVisibility(set);
      });

      privateState.dataStatus = DATA_STATUS.hasLayers;
    }

    /** Removes all the dataset layers from the map */
    function removeLayers() {
      if (dataStatus.value === DATA_STATUS.hasLayers) {
        datasets.value?.forEach((set) => {
          if (set.layer instanceof BaseLayer) {
            theMap.value!.removeLayer(set.layer);
          }
          if (set.lineLayer instanceof BaseLayer) {
            theMap.value!.removeLayer(set.lineLayer);
          }
          if (set.polyLayer instanceof BaseLayer) {
            theMap.value!.removeLayer(set.polyLayer);
          }
        });
        if (privateState.selectedFeatureLayer) {
          theMap.value!.removeLayer(privateState.selectedFeatureLayer);
        }
      }
    }

    /** Sets the visibility for each of a datasets layers based on the `isVisible` property and the respective show ref.
     * @param {ViewerDataset} dataset Dataset to update layer visibilties on
     */
    function updateLayerVisibility(dataset: ViewerDataset) {
      dataset.layer?.setVisible(dataset.isVisible && showPoints.value);
      dataset.lineLayer?.setVisible(dataset.isVisible && showLines.value);
      dataset.polyLayer?.setVisible(dataset.isVisible && showPolygons.value);
    }

    /** Iterates through all the datasets and updates their layer visibilities */
    function updateLayersVisibility() {
      datasets.value?.forEach((set) => {
        updateLayerVisibility(set);
      });
    }

    /** Updates the active dataset query based on dataset id and if it is included
     * @param {number} id Dataset Id
     * @param {boolean} isIncluded Indicator that the current dataset belongs in the active dataset queries
     */
    function updateActiveDatasetQuery(id: number, isIncluded: boolean) {
      const dataset = datasets.value.get(id);
      updateLayerVisibility(dataset!);

      const index = activeDatasetQueries.value.indexOf(id);
      if (index > -1) {
        if (!isIncluded)
          //I assume this if isn't technically necessary - I just added it in case it somehow got off track. Remove if you wish.
          activeDatasetQueries.value.splice(index, 1);
      } else {
        if (isIncluded) activeDatasetQueries.value.push(id);
      }
      getQueryFromMeta();
    }

    /** Updates the layer visibility for a dataset
     * @param {ViewerDataset} dataset Dataset to toggle
     * @param {boolean} newValue Value representing if the dataset layer should or shouldn't be visible.
     */
    function toggleLayer(dataset: ViewerDataset, newValue: boolean) {
      // Prevents infinite update loops
      if (dataset.isVisible !== newValue) {
        dataset.isVisible = !dataset.isVisible;
        updateActiveDatasetQuery(dataset.id, dataset.isVisible);
      }
    }

    /** Toggles the visibility of the points layer */
    function togglePoints() {
      //preventing recursion caused when changing the value via the reset triggers the update:model-value
      if (programmaticResetPoints.value) {
        programmaticResetPoints.value = false;
        return;
      }

      privateState.showPoints = !privateState.showPoints;
      updateLayersVisibility();
    }

    /** Toggles the visibility of the lines layer */
    function toggleLines() {
      //preventing recursion caused when changing the value via the reset triggers the update:model-value
      if (programmaticResetLines.value) {
        programmaticResetLines.value = false;
        return;
      }

      privateState.showLines = !privateState.showLines;
      updateLayersVisibility();
    }

    /** Toggles the visibility of the polygons layer */
    function togglePolygons() {
      //preventing recursion caused when changing the value via the reset triggers the update:model-value
      if (programmaticResetPoly.value) {
        programmaticResetPoly.value = false;
        return;
      }

      privateState.showPolygons = !privateState.showPolygons;
      updateLayersVisibility();
    }

    /** Closes the details section */
    function closeDetails() {
      if (previousPane.value === "sidebar") {
        if (isMobileMode.value) detailsShow();
        else detailsHide();
        sidebarShow();
      }
      if (previousPane.value === "map") {
        if (isMobileMode.value) detailsHide();
        else detailsShow();
      }
    }

    /** Opens the Details section from the Map */
    function openDetailsFromMap() {
      previousPane.value = "map";
      detailsMax();
    }

    /** Opens the Details section from the Sidebar */
    function openDetailsFromSidebar() {
      previousPane.value = "sidebar";
      detailsMax();
    }

    /** Hides the details  */
    function detailsHide() {
      showDetails.value = "hide";
    }
    /** Maximizes the size of the details */
    function detailsMax() {
      showDetails.value = "maximize";
    }
    /** Shows the details section */
    function detailsShow() {
      showDetails.value = "show";
    }

    /** Hides the sidebar section */
    function sidebarHide() {
      showSidebar.value = false;
    }
    /** Shows the sidebar section */
    function sidebarShow() {
      showSidebar.value = true;
    }
    /** Hides the detauls and sidebar */
    function mapShow() {
      if (isMobileMode.value) detailsHide();
      sidebarHide();
    }

    /** Retrives a dataset via its it
     * @param {number} id Id of the dataset. Should match an id of one of the existing datasets.
     */
    function getDataset(id: number) {
      const result = datasets.value.get(id);

      if (result === undefined) {
        throw new Error("Dataset not found");
      }

      return result;
    }

    /** Zooms to an area of the map
     * @param {ZoomToOption} to The desired zoom to option
     */
    function zoomTo(to: ZoomToOption) {
      const theView = theMap.value?.getView();
      const options: FitOptions = {
        duration: viewerConfigStore.zoomDuration,
        maxZoom: to.maxZoom ?? 13,
        padding: [75, 75, 75, 75],
      };
      if (to.minZoom) {
        options.minResolution = to.minZoom;
        options.nearest = true;
      }

      theView?.fit(to.geometry.getExtent(), options);
    }

    /** Updates the reference layer when a change is detected
     * @param {number} layerId Id of the reference layer
     * @param {boolean | undefined} checked Determines if the reference layer is active
     */
    function handleReferenceChange(layerId: number, checked: boolean | undefined) {
      if (checked !== undefined && referenceLayers.value?.get(layerId)?.source) {
        referenceLayers.value.get(layerId)!.source.active = checked;
      }
      updateReferenceLayers();
    }

    /** Converts the provided reference layers into a map for easier processing */
    function refLayerToMap() {
      referenceLayers.value = viewerConfigStore.referenceLayers.reduce((acc, layer) => {
        acc.set(layer.source.id, layer);
        return acc;
      }, new Map());
    }

    /** Combines desired prefined base layers with additionally provided base layers into one reference */
    function getBaseLayersOptions() {
      if (baseLayers.value.length > 0) return;

      if (viewerConfigStore.baseLayerOptionsIds.length > 0) {
        // console.log("preDefinedBaseLayers", preDefinedBaseLayers, viewerConfigStore.baseLayerOptionsIds);
        preDefinedBaseLayers.forEach((layer) => {
          if (viewerConfigStore.baseLayerOptionsIds.includes(layer.source.id)) {
            baseLayers.value.push({ label: layer.label, value: layer.source.id, source: { ...layer.source } }); //make a shallow copy because formkit will mutate it.
          }
        });
      }

      if (viewerConfigStore.additionalBaseLayers.length > 0) {
        viewerConfigStore.additionalBaseLayers.forEach((layer) => {
          baseLayers.value.push({ label: layer.label, value: layer.source.id, source: { ...layer.source } });
        });
      }
    }

    /** Ensures that all base layer ids are sufficient. (Default is provided, included ids exist, provided ids aren't duplicated) */
    function validateBaseLayerIds() {
      //make sure default id is provided
      if (!viewerConfigStore.defaultBaseLayerId) {
        throw new Error("Must provide a default base layer is");
      }
      const allIds: number[] = [];
      //make sure the id (base layer) you try to include does exist
      if (viewerConfigStore.baseLayerOptionsIds.length > 0) {
        viewerConfigStore.baseLayerOptionsIds.forEach((id) => {
          if (!preDefinedBaseLayersIds.value.includes(id)) {
            throw new Error(`Base layer id ${id} does not exist`);
          }
          allIds.push(id);
        });
      }
      //make sure the id you choose if you add a layer isn't already in use
      if (viewerConfigStore.additionalBaseLayers.length > 0) {
        viewerConfigStore.additionalBaseLayers.forEach((layer) => {
          if (preDefinedBaseLayersIds.value.includes(layer.source.id)) {
            throw new Error(`Base layer id ${layer.source.id} is already being used`);
          }
          allIds.push(layer.source.id);
        });
      }
      //make sure the default id you select exists either in the included or added base layers
      if (viewerConfigStore.defaultBaseLayerId != null) {
        if (!allIds.includes(viewerConfigStore.defaultBaseLayerId)) {
          throw new Error(`Default id ${viewerConfigStore.defaultBaseLayerId} does not exist`);
        }
      }
      //make sure there are no duplicate ids (technically this is almost the same as the second check, this one is just to make sure that you didn't use the same id twice in the ones you added)
      if (new Set(allIds).size != allIds.length) {
        throw new Error("Duplicate base layer id's cannot exist. Please remove duplicates.");
      }
    }

    /** Ensures there are no duplicate reference layer ids. This is important because this how the reference layers are identified.  */
    function validateRefLayerIds() {
      const allIds = viewerConfigStore.referenceLayers.map((item) => item.source.id);
      if (allIds.length !== new Set(allIds).size) {
        throw new Error("Cannot use duplicate id's for reference layers");
      }
    }

    /** Updates the base layer for the map. If the new base layer as a reference layer, it also applies it. This shouldn't be confused with the other type of reference layer.
     * @param {number | undefined} layerId Id of the new base layer
     * @param {LayerGroup} baseLayerGroup The base layer group in use for the map
     */
    function updateBaseLayer(layerId: number | undefined, baseLayerGroup: LayerGroup) {
      const layer = baseLayers.value.find((x) => x.source.id === layerId)?.source;
      if (!layer) return;

      const layers = baseLayerGroup.getLayers();

      // Although there may be multiple "base" layers, the bottom-most one will always be the actual layer. Above that are ref layers.
      const currentLayer = layers.item(0);
      if (layer.type === "osm" || layer.type === "google") {
        // Awkward, but url should be unique.
        if (layer.url === currentLayer?.get("source")?.urls[0]) return;

        const currentBaseLayer = new TileLayer({
          source: new XYZ({
            url: layer.url,
            attributions: layer.attribution ? [layer.attribution] : [],
          }),
        });
        layers.clear();

        layers.push(currentBaseLayer);
        if (layer.refUrl) {
          const currentBaseRefLayer = new TileLayer({
            source: new XYZ({
              url: layer.refUrl,
              attributions: layer.attribution ? [layer.attribution] : [],
            }),
          });
          layers.push(currentBaseRefLayer);
        }
      } else if (layer.type === "arcgis") {
        // Awkward, but url should be unique.
        if (layer.url === currentLayer?.get("source")?.urls[0]) return;

        const currentBaseLayer = new TileLayer({
          source: new TileArcGISRest({
            url: layer.url,
            attributions: layer.attribution ? [layer.attribution] : [],
          }),
        });
        layers.clear();

        layers.push(currentBaseLayer);
        if (layer.refUrl) {
          const currentBaseRefLayer = new TileLayer({
            source: new TileArcGISRest({
              url: layer.refUrl,
              attributions: layer.attribution ? [layer.attribution] : [],
            }),
          });
          layers.push(currentBaseRefLayer);
        }
      }
      baseLayerGroup.changed();
    }
    /** Updates the reference layers */
    function updateReferenceLayers() {
      const layers = referenceLayerGroup.value.getLayers();
      //TOOD: test if trying to individually add/remove each reference layer from the group will prevent refetching/recreation (harder to do, but could be more performant)

      //looping through them all incase somehow it got off track, rather than just switching the one selected. Can change.
      referenceLayers.value?.forEach((layer: LayerSource) => {
        if (layer.source.active && !currentReferenceLayers.value.get(layer.source.id)) {
          if (layer.source.type === "osm" || layer.source.type === "google") {
            //may change
            const newLayer = new TileLayer({
              source: new XYZ({
                url: layer.source.url,
                attributions: layer.source.attribution ? [layer.source.attribution] : undefined,
              }),
              maxZoom: layer.source.maxZoom,
              minZoom: layer.source.minZoom,
            });
            currentReferenceLayers.value.set(layer.source.id, newLayer);
            layers.push(newLayer);
          } else if (layer.source.type === "arcgis") {
            const esriJsonFormat = new EsriJSON();
            const vectorSource = new VectorSource({
              loader: function (extent, resolution, projection, success, failure) {
                const url =
                  layer.source.url +
                  "/" +
                  layer.source.layerId +
                  "/query/?f=json&" +
                  "returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=" +
                  encodeURIComponent(
                    '{"xmin":' +
                      extent[0] +
                      ',"ymin":' +
                      extent[1] +
                      ',"xmax":' +
                      extent[2] +
                      ',"ymax":' +
                      extent[3] +
                      ',"spatialReference":{"wkid":102100}}',
                  ) +
                  "&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*" +
                  "&outSR=102100";

                axios.get(url).then((response) => {
                  if (response && response.status === 200) {
                    const features = esriJsonFormat.readFeatures(response.data, {
                      featureProjection: projection,
                    });

                    features.forEach((feature) => {
                      // feature.set("extra", extraProperties);
                    });

                    if (features.length > 0) {
                      vectorSource.addFeatures(features);
                      success?.(features);
                      return;
                    }
                  }
                  failure?.();
                });
              },
              strategy: tile(
                createXYZ({
                  tileSize: 512,
                }),
              ),
            });

            const newLayer = new VectorLayer({
              source: vectorSource,
              // new VectorSource({
              //   url: layer.value.url,
              //   attributions: layer.value.attribution ? [layer.value.attribution] : [],
              //   format: new EsriJSON(),
              // }),
              maxZoom: layer.source.maxZoom,
              minZoom: layer.source.minZoom,
              style: function (feature) {
                return new Style({
                  fill: new Fill({
                    color: layer.source.color,
                  }),
                  stroke: new Stroke({
                    color: "rgba(110, 110, 110, 255)",
                    width: 0.4,
                  }),
                });
              },
            });

            currentReferenceLayers.value.set(layer.source.id, newLayer);
            layers.push(newLayer);
          }
        } else if (!layer.source.active && currentReferenceLayers.value.get(layer.source.id)) {
          currentReferenceLayers.value.delete(layer.source.id);
        }
      });

      layers.clear();
      const currentLayersArray = Array.from(currentReferenceLayers.value.values());
      currentLayersArray.forEach((layer) => {
        layers.push(layer);
      });
    }

    return {
      // state
      theMap,
      showSidebar,
      showDetails,
      previousPane,
      currentSidebarPanel,
      hasActiveTools,
      datasets,
      dataProjection,
      featureProjection,
      projection,
      centerCoordinates,
      zoom,
      datasetMetadata,
      metadataContext,
      additionalMetadata,
      syncedQueryRef,
      datasetPayloadRef,
      activeDatasetQueries,
      preDefinedBaseLayers,
      baseLayers,
      referenceLayerGroup,
      currentReferenceLayers,
      referenceLayers,
      allLayerGroups,
      progUpdateMap,

      // getters
      preDefinedBaseLayersIds,
      showPoints,
      showLines,
      showPolygons,
      dataStatus,
      currentLocation,
      currentLocationId,
      currentDatasetLabel,
      isMobileMode,
      hasReferenceLayers,

      // actions
      fetchDatasets,
      getPins,
      fill,
      selectFeatureAtPixel,
      selectFeature,
      reset,
      initialize,
      hydrateDatasetMetadata,
      setCenterCoordinates,
      setCurrentLocationUsingFeature,
      setCurrentLocationUsingILocation,
      setCurrentLocation,
      getQueryFromMeta,
      assignDatasetLettersAndGroups,
      addDatasets,
      getStyle,
      selectStyle,
      getVectorSource,
      getPinsHandler,
      doSearch,
      resetDatasetResults,
      getPointStyle,
      createLayers,
      addLayers,
      removeLayers,
      updateLayerVisibility,
      updateLayersVisibility,
      updateActiveDatasetQuery,
      toggleLayer,
      togglePoints,
      toggleLines,
      togglePolygons,
      closeDetails,
      openDetailsFromMap,
      openDetailsFromSidebar,
      detailsHide,
      detailsMax,
      detailsShow,
      sidebarHide,
      sidebarShow,
      mapShow,
      getDataset,
      zoomTo,
      handleReferenceChange,
      refLayerToMap,
      getBaseLayersOptions,
      validateBaseLayerIds,
      validateRefLayerIds,
      updateBaseLayer,
      updateReferenceLayers,
    };
  },
);

export type UseViewerStore = ReturnType<typeof useViewerStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useViewerStore, import.meta.hot));
}
