<template>
  <router-view />
  <BaseNotifications />
  <TheCssBreakpointDetector />
</template>

<script setup lang="tsx">
import { routeNames } from "@casa/routes";
import { TheCssBreakpointDetector, useAppNav } from "@mui/components/common";
import { BaseNotifications } from "@mui/components/notifications";

import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

// Example of setting an application title. Remember to style it for both desktop and mobile.
const SmallComponent = () => <div class="ml-2">MUI</div>;
setApplicationTitleComponent(SmallComponent);

// Nav items should be set once during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [{ label: "Dashboard", name: routeNames.home }],
  },
  {
    label: "Casas",
    className: "",
    items: [
      { label: "Abodes", name: routeNames.abodes },
      { label: "Appraisals", name: routeNames.appraisals },
    ],
  },
  {
    label: "Examples",
    className: "",
    items: [
      { label: "DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit", name: routeNames.detailedAudit },
      { label: "Schema", name: routeNames.schema },
      { label: "More DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit More", name: routeNames.detailedAudit },
      { label: "Schema Again", name: routeNames.schema },
    ],
  },
  {
    label: "Additional Examples",
    className: "",
    items: [
      { label: "DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit", name: routeNames.detailedAudit },
      { label: "Schema", name: routeNames.schema },
      { label: "More DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit More", name: routeNames.detailedAudit },
      { label: "Schema Again", name: routeNames.schema },
    ],
  },
  {
    label: "Documentation",
    className: "",
    items: [{ label: "Documentation", name: routeNames.documentation }],
  },
  {
    label: "Another Category Title",
    className: "",
    items: [
      { label: "DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit", name: routeNames.detailedAudit },
      { label: "Schema", name: routeNames.schema },
      { label: "More DocGen Examples", name: routeNames.docGen },
      { label: "Detailed Audit More", name: routeNames.detailedAudit },
      { label: "Schema Again", name: routeNames.schema },
    ],
  },
  {
    label: "misc",
    className: "3xl:break-before-column",
    showLabel: false,
    items: [
      { label: "Viewer", name: routeNames.viewer },
      { label: "Not Contacts", name: routeNames.viewer },
    ],
  },
]);
</script>
